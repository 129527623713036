// ============Land-and-soil-Characteristic============
export const landAndSoilChar = '/configuration/land-and-soil-characteristic'
export const landAndSoilCharList = landAndSoilChar + '/list'
export const landAndSoilCharStore = landAndSoilChar + '/store'
export const landAndSoilCharUpdate = landAndSoilChar + '/update'
export const landAndSoilCharToggleStatus = landAndSoilChar + '/toggle-status'

// Off days
export const offDaysListApi = '/configuration/off-days/list'
export const offDaysStoreApi = '/configuration/off-days/store'
export const offDaysUpdateApi = '/configuration/off-days/update'

// Tour operators
export const tourOperatorListApi = '/configuration/tour-operator/list'
export const tourOperatorStoreApi = '/configuration/tour-operator/store'
export const tourOperatorUpdateApi = '/configuration/tour-operator/update'
export const tourOperatorToggleApi = '/configuration/tour-operator/toggle-status'

// ============Soil Information============
export const soilInfo = '/museum-information/soil-information'
export const soilInfoList = soilInfo + '/list'
export const soilInfoStore = soilInfo + '/store'
export const soilInfoUpdate = soilInfo + '/update'
export const soilInfoToggleStatus = soilInfo + '/toggle-status'

// museum information
export const museumInfoListApi = '/museum-info/museum/list'
export const museumInfoStoreApi = '/museum-info/museum/store'
export const museumInfoUpdateApi = '/museum-info/museum/update'
export const museumInfoToggleStatusApi = '/museum-info/museum/toggle-status'

// pest information
export const pestInfoListApi = '/museum-information/pest-information/list'
export const pestInfoStoreApi = '/museum-information/pest-information/save'
export const pestInfoToggleStatusApi = '/museum-information/pest-information/toggle-status'

// crop information
export const cropInfoListApi = '/museum-information/crop/list'
export const cropInfoStoreApi = '/museum-information/crop/store'
export const cropInfoUpdateApi = '/museum-information/crop/update'
export const cropInfoToggleStatusApi = '/museum-information/crop/toggle-status'
